import React from "react";
import styles from "./SolutionBanner.module.css";
import Button from "../../atoms/Button";
import ScrollLink from "../../atoms/ScrollLink";

function SolutionBanner(props){
    return <div className={styles.bannerContainer} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/${props.solution.primaryImagePath})`}}>
        {props.solution.icon}
        <h1 className={styles.title} data-aos="fade-up" data-aos-offset={0}>{props.solution.title}</h1>
        <ScrollLink to="#solution-capabilities" smooth>
            <Button role="primary">Подробнее</Button>
        </ScrollLink>
    </div>;
}

export default SolutionBanner;