import React from "react";
import styles from "./SystemSchemeBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import SystemSchemeCardsList from "../../molecules/SystemSchemeCardsList";
import Button from "../../atoms/Button";
import DataProtectionBanner from "../../molecules/DataProtectionBanner";

class SystemSchemeBlock extends React.Component{
    render(){
        return (
            <div className={styles.container} id="scheme">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true} style={{paddingLeft: '15px', paddingRight: '15px'}}>Принцип работы системы видеоаналитики</BlockTitle>
                    <SystemSchemeCardsList/>
                    <BlockParagraph centerDesktop={true} centerMobile={true} style={{marginTop: '60px'}}>Подробная схема интеграции системы видеоаналитики с оборудованием заказчика:</BlockParagraph>
                    <div className={styles.buttonContainer}>
                        <a target="_blank" rel="noreferrer noopener" href={`${process.env.PUBLIC_URL}/scheme.pdf`}>
                            <Button type='accent'>Скачать | PDF (66KB)</Button>
                        </a>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default SystemSchemeBlock;