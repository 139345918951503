import React from "react";
import styles from "./Dialog.module.css";
import CloseButton from "../../atoms/CloseButton/CloseButton";

function Dialog(props){
    let additionalClassName = props.isShown ? 'shown' : 'hidden';
    return (
        <div className={`${styles.dialogWrapper} ${additionalClassName ? styles[additionalClassName] : ''}`}>
            <div className={styles.closeButtonContainer}><CloseButton onClick={props.onDialogClose}/></div>
            {props.children}
        </div>
    );
}

export default Dialog;