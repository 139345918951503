import React from "react";

function PlayIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27">
            <path fill="#ff981e"
                  d="M445,1489.49a1.5,1.5,0,0,1,1.488-1.49h27.024a1.491,1.491,0,0,1,1.488,1.49v24.02a1.5,1.5,0,0,1-1.488,1.49H446.488a1.491,1.491,0,0,1-1.488-1.49v-24.02Zm3,1.51v21h24v-21H448Zm9.933,5.12,7.318,4.88a0.6,0.6,0,0,1,.168.83,0.525,0.525,0,0,1-.168.17l-7.32,4.88a0.6,0.6,0,0,1-.931-0.5v-9.76a0.6,0.6,0,0,1,.6-0.6A0.646,0.646,0,0,1,457.933,1496.12Z"
                  transform="translate(-445 -1488)"/>
        </svg>
    );
}

export default PlayIcon;