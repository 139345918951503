import React, {useRef} from "react";
import styles from "./CapabilityCardsList.module.css";
import CapabilityCard from "../CapabilityCard";
import VisitorsControl from "../../atoms/images/CapabilitiesIcons/VisitorsControl";
import RecognitionSystem from "../../atoms/images/CapabilitiesIcons/RecognitionSystem";
import AreaControl from "../../atoms/images/CapabilitiesIcons/AreaControl";
import StaffControl from "../../atoms/images/CapabilitiesIcons/StaffControl";
import SliderIndicators from "../SliderIndicators";

class CapabilityCardsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scrollEventHandler: null,
            totalAmount: 4
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }

    render () {
        return <div>
                    <div className={styles.cardsContainer}  onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                        <CapabilityCard key={0}>
                            <VisitorsControl/>
                            <h3>Контроль посетителей</h3>
                            <p>Подсчет посетителей, фокус внимания, время пребывания
                                на объекте, тепловая карта, карта траекторий</p>
                        </CapabilityCard>
                        <CapabilityCard key={1}>
                            <RecognitionSystem/>
                            <h3>Система распознавания</h3>
                            <p>Честное распознавание лиц, объектов, предметов,
                                автомобилей, номерных знаков (с дальнейшим анализом)</p>
                        </CapabilityCard>
                        <CapabilityCard key={2}>
                            <AreaControl/>
                            <h3>Контроль за объектами</h3>
                            <p>Контроль торгового объекта, кассы, парковки, территории,
                                с различными сценариями и индивидуальной настройкой</p>
                        </CapabilityCard>
                        <CapabilityCard key={3}>
                            <StaffControl/>
                            <h3>Контроль за персоналом</h3>
                            <p>Техника безопасности (каска, спецодежда, колпаки, перчатки), контроль регламента персонала,  халатность на рабочем месте</p>
                        </CapabilityCard>
                        <div className={styles.mock}> </div>
                    </div>
                    <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
                </div>
    };
}

export default CapabilityCardsList;