import React from "react";
import styles from "./ContactForm.module.css";
import Button from "../../atoms/Button";
import FormInput from "../../atoms/FormInput";
import LogoLoader from "../../atoms/images/LogoLoader";
import FormInputPhone from "../../molecules/FormInputPhone";

class ContactForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            phone: {value: '', isValid: true},
            name: {value: '', isValid: true},
            formStatus: 'normal',
        }
    }

    setFormStatus(status){
        this.setState({
            phone: this.state.phone,
            name: this.state.name,
            formStatus: status,
        });
    }

    handleFormSubmit(){
        if (this.validateInputs()){
            this.setFormStatus('loading');
            fetch('/backend/?action=submitContactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    phone: this.state.phone.value.replace(/[^\d]/g, ''),
                    name: this.state.name.value.trim()
                }),
            }).then(response => {
                if (response.ok){
                    this.setState({
                        phone: {value: '', isValid: true},
                        name: {value: '', isValid: true},
                        formStatus: this.state.formStatus,
                    });
                    this.setFormStatus('success');
                }else{
                    this.setFormStatus('fail');
                }
            }).catch(error => {
                this.setFormStatus('fail');
            });
        }
    }

    handleNameChange(event){
        const value = event.target.value;
        this.setState({
            phone: this.state.phone,
            name: {value: value, isValid: true}
        });
    }

    handlePhoneChange(event){
        const value = event.target.value;
        this.setState({
            phone: {value: value, isValid: true},
            name: this.state.name
        });
    }

    validateInputs(){
        const phoneIsValid = this.validatePhoneInput();
        const nameIsValid = this.validateNameInput();
        this.setState({
            phone: {value: this.state.phone.value, isValid: phoneIsValid},
            name: {value: this.state.name.value, isValid: nameIsValid}
        });
        return  phoneIsValid && nameIsValid;
    }

    validatePhoneInput(){
        return /^(\d{9})$/.test(this.state.phone.value.replace(/[^\d]/g, ''));
    }

    validateNameInput(){
        return this.state.name.value !== '';
    }

    render(){
        return (
            <div className={styles.contactFormWrapper}>
                <div className={`${styles.failScreen} ${this.state.formStatus === 'fail' ? '' : styles.hidden}`}>
                    <h3 className={styles.formSubmittedTitle}>Ошибка</h3>
                    <p className={styles.formSubmittedText}>Не удалось отправить заявку</p>
                    <Button role="primary" onClick={() => {this.setFormStatus('normal')}}>Попробовать снова</Button>
                </div>
                <div className={`${styles.successScreen} ${this.state.formStatus === 'success' ? '' : styles.hidden}`}>
                    <h3 className={styles.formSubmittedTitle}>Заявка отправлена</h3>
                    <p className={styles.formSubmittedText}>Спасибо за обращение! Мы постараемся связаться с Вами как можно скорее</p>
                    <Button role="primary" onClick={() => {this.setFormStatus('normal')}}>Понятно</Button>
                </div>
                <div className={`${styles.loadingScreen} ${this.state.formStatus === 'loading' ? '' : styles.hidden}`}>
                    <LogoLoader/>
                    <div className={styles.loadingTitle}>Отправка</div>
                </div>
                <div className={`${styles.formContent} ${this.state.formStatus === 'normal' ? '' : styles.hidden}`}>
                    <h3 className={styles.formTitle}>Мы вам перезвоним</h3>

                    <div className={styles.inputsWrapper}>
                        <div className={styles.inputWrapper}>
                            <label className={styles.formLabel} htmlFor="contact-form-phone">
                                Номер телефона
                            </label>
                            <FormInputPhone id="contact-form-phone" valueIsValid={this.state.phone.isValid} value={this.state.phone.value} onInput={(event) => {this.handlePhoneChange(event)}}/>
                        </div>
                        <div className={styles.inputWrapper}>
                            <label className={styles.formLabel} htmlFor="contact-form-name">
                                Ваше имя
                            </label>
                            <FormInput id="contact-form-name" maxLength="64" valueIsValid={this.state.name.isValid}  value={this.state.name.value} onInput={(event) => {this.handleNameChange(event)}}/>
                        </div>
                    </div>

                    <Button role="primary" onClick={() => {this.handleFormSubmit()}}>Перезвоните мне</Button>
                </div>
            </div>
        );
    }
}

export default ContactForm;