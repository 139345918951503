import React from "react";
import styles from "./NavigationBar.module.css";
import NavigationListItem from "../../atoms/NavigationListItem";
import NavigationList from "../../molecules/NavigationList";
import CenteredContainer from "../../molecules/CenteredContainer";
import Logo from "../../atoms/images/Logo";
import SocialLinks from "../../molecules/SocialLinks";
import NavigationBarDropdown from "../../molecules/NavigationBarDropdown";
import MenuToggle from "../../atoms/MenuToggle";
import ScrollLink from "../../atoms/ScrollLink";

class NavigationBar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pageIsScrolledDown: false,
            scrollEventListener: this.handleScrollEvent.bind(this),
            mobileMenuIsExpanded: false
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.state.scrollEventListener);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll',this.state.scrollEventListener);
    }

    handleScrollEvent(event){
        this.setState({
            pageIsScrolledDown: window.pageYOffset > 50,
            scrollEventListener: this.state.scrollEventListener,
            mobileMenuIsExpanded: this.state.mobileMenuIsExpanded
        });
    }

    toggleMobileMenu(){
        this.setState({
            mobileMenuIsExpanded: !this.state.mobileMenuIsExpanded,
            pageIsScrolledDown: this.state.pageIsScrolledDown,
            scrollEventListener: this.state.scrollEventListener
        });
    }

    hideMobileMenu(){
        this.setState({
            mobileMenuIsExpanded: false,
            pageIsScrolledDown: this.state.pageIsScrolledDown,
            scrollEventListener: this.state.scrollEventListener
        });
    }

    render(){
        return (
            <div className={`${styles.navigationBar} ${this.state.pageIsScrolledDown ? styles.scrolled : styles.initial} ${this.state.mobileMenuIsExpanded ? styles.mobileMenuExpanded : styles.mobileMenuCollapsed}`} id="menu">
                <CenteredContainer>
                    <div onClick={() => {this.hideMobileMenu()}}>
                        <ScrollLink className={styles.logoLink} to="/#top" navigateTop ><Logo/></ScrollLink>
                    </div>
                    <div onClick={() => {this.toggleMobileMenu()}}>
                        <MenuToggle isExpanded={this.state.mobileMenuIsExpanded}/>
                    </div>
                    <NavigationList isExpanded={this.state.mobileMenuIsExpanded}>
                        <NavigationListItem to="/#scheme" onClick={() => {this.hideMobileMenu()}}>О системе</NavigationListItem>
                        <NavigationBarDropdown onNavigationItemClick={() => {this.hideMobileMenu()}}/>
                        <NavigationListItem to="/#visible-confusion" onClick={() => {this.hideMobileMenu()}}>С чего начать</NavigationListItem>
                        <NavigationListItem to="/#contacts" onClick={() => {this.hideMobileMenu()}}>Контакты</NavigationListItem>
                    </NavigationList>
                    <div className={styles.socialLinksWrapper}>
                        <SocialLinks/>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default NavigationBar;