import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import ContactsBlock from "../../organisms/ContactsBlock";
import Footer from "../../organisms/Footer";
import SolutionBannerBlock from "../../organisms/SolutionBannerBlock";
import SolutionCapabilitiesBlock from "../../organisms/SolutionCapabilitiesBlock";
import SystemConnectionBlock from "../../organisms/SystemConnectionBlock";
import FullReportingBlock from "../../organisms/FullReportingBlock";

class SolutionPageTemplate extends React.Component{
    render(){
        return (
            <div>
                <NavigationBar/>
                <SolutionBannerBlock solution={this.props.solution}/>
                <SolutionCapabilitiesBlock solution={this.props.solution}/>
                <SystemConnectionBlock/>
                <FullReportingBlock/>
                <ContactsBlock  handleContactFormToggle={this.props.handleContactFormToggle}/>
                <Footer/>
            </div>
        );
    }
}

export default SolutionPageTemplate;