import React from "react";
import styles from "./IntroductionForm.module.css";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import Button from "../../atoms/Button";
import ScrollLink from "../../atoms/ScrollLink";

function IntroductionForm(props){
    return (
        <div className={styles.introductionForm}>
            <BlockTitle type='large'>Видеоаналитика <br/> <span className={styles.blockMobile}>на основе</span> нейронных сетей</BlockTitle>
            <BlockParagraph>Система подключается к вашим камерам видеонаблюдения, просматривает все видео за вас, и говорит где нужно ваше внимание, а так же собирает и анализирует данные по заданным параметрам, без прямого участия человека</BlockParagraph>
            <div className={styles.blockButtons}>
                <ScrollLink to="/#capabilities" smooth>
                    <Button role="primary" >Возможности системы</Button>
                </ScrollLink>
                <a target="_blank" rel="noreferrer noopener" href={`${process.env.PUBLIC_URL}/cvc_promo.pdf`}>
                    <Button role="secondary" >Презентация | PDF (418KB)</Button>
                </a>
            </div>
        </div>
    );
}

export default IntroductionForm;