import React from "react";
import styles from "./FooterColumns.module.css";

function FooterColumns(props){
    return (
        <div className={styles.footerColumns}>
            <div className={styles.column}>
                <h4 className={styles.colHeader}>Клиентам:</h4>
                <a className={styles.colValue} href="tel:+375447709709">+375 (44) 7-709-709</a>
                <a className={styles.colValue} href="mailto:support@cvcgroup.by">support@cvcgroup.by</a>
            </div>
        </div>
    );
}

export default FooterColumns;