import React from "react";

function InstagramLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path fill="black"
                  d="M1501.63,95.1a3.008,3.008,0,0,0-1.73-1.737,5.112,5.112,0,0,0-1.7-.315c-0.97-.044-1.26-0.054-3.7-0.054s-2.73.01-3.7,0.054a5.112,5.112,0,0,0-1.7.315,2.922,2.922,0,0,0-1.05.684,2.779,2.779,0,0,0-.68,1.052,4.888,4.888,0,0,0-.32,1.7c-0.04.964-.05,1.253-0.05,3.695s0.01,2.734.05,3.7a4.9,4.9,0,0,0,.32,1.7,2.788,2.788,0,0,0,.68,1.051,2.918,2.918,0,0,0,1.05.684,5.091,5.091,0,0,0,1.7.315c0.97,0.044,1.26.054,3.7,0.054s2.73-.01,3.7-0.054a5.091,5.091,0,0,0,1.7-.315,3.049,3.049,0,0,0,1.73-1.735,4.9,4.9,0,0,0,.32-1.7c0.04-.963.05-1.253,0.05-3.7s-0.01-2.731-.05-3.695A4.888,4.888,0,0,0,1501.63,95.1ZM1494.5,105.2a4.7,4.7,0,1,1,4.7-4.7A4.7,4.7,0,0,1,1494.5,105.2Zm4.88-8.484a1.1,1.1,0,1,1,1.1-1.1A1.1,1.1,0,0,1,1499.38,96.711Zm-1.83,3.785a3.05,3.05,0,1,1-3.05-3.049A3.053,3.053,0,0,1,1497.55,100.5Z"
                  transform="translate(-1487 -93)"/>
        </svg>
    );
}

export default InstagramLogo;