import React, { useEffect } from 'react';

export default function useResize(callback){
    useEffect(() => {
        const cb = (event) => {
            callback(event);
        };
        window.addEventListener('resize', cb);
        return () => {
            window.removeEventListener('resize', cb);
        };
    });
}