import React from "react";
import styles from "./SystemSchemeCard.module.css";

function SystemSchemeCard(props){
    return (
        <div data-aos="fade-right" data-aos-delay={(props.cardIndex - 1) * 150} className={`${styles.schemeCard}`}>
            <div className={styles.indexCircle}>{props.cardIndex ? props.cardIndex : 0}</div>
            <div className={styles.innerContainer}>{props.children}</div>
        </div>
    );
}

export default SystemSchemeCard;