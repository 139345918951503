import React from "react";
import styles from "./Footer.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import Button from "../../atoms/Button";
import SocialLinks from "../../molecules/SocialLinks";
import FooterColumns from "../../molecules/FooterColumns";
class Footer extends React.Component{
    render(){
        return (
            <CenteredContainer>
                <div className={styles.footer}>
                    <div className={styles.mainContent}>
                        <a className={styles.mobileHidden} target="_blank" rel="noreferrer noopener" href={`${process.env.PUBLIC_URL}/cvc_promo.pdf`}>
                            <Button role='secondary'>Презентация | PDF (418KB)</Button>
                        </a>
                        <FooterColumns/>
                        <a className={styles.hiddenDesktop} target="_blank" rel="noreferrer noopener" href={`${process.env.PUBLIC_URL}/cvc_promo.pdf`}>
                            <Button role='secondary'>Презентация | PDF (418KB)</Button>
                        </a>
                        <SocialLinks/>
                    </div>
                    <div className={styles.copyright}>Cyber Video Control Group, Минск, Беларусь</div>
                </div>
            </CenteredContainer>
        );
    }
}

export default Footer;