import React from "react";
import styles from "./SolutionBannerBlock.module.css";
import SolutionBanner from "../../molecules/SolutionBanner";
import ScrollArrowImage from "../../atoms/images/ScrollArrowImage";
import Button from "../../atoms/Button";
import ScrollLink from "../../atoms/ScrollLink";

function SolutionBannerBlock(props){
    return <div className={styles.bannerBlock}>
        <SolutionBanner solution={props.solution}/>
        <ScrollLink to="#solution-capabilities" smooth>
            <div className={styles.scrollButtonContainer}>
                <ScrollArrowImage/>
            </div>
        </ScrollLink>

    </div>
}

export default SolutionBannerBlock;