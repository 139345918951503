import React from "react";
import styles from "./SocialLinkItem.module.css";
import FacebookLogo from "../images/SocialLogos/FacebookLogo"
import TwitterLogo from "../images/SocialLogos/TwitterLogo"
import InstagramLogo from "../images/SocialLogos/InstagramLogo"

function SocialLinkItem(props){
    const providedNetworkName = props.name ? props.name.trim().toLowerCase() : 'facebook';
const existingSocialNetworks = {
        'facebook': {uri: 'https://facebook.com/cvcgroupby', logoImage: <FacebookLogo/>},
        'twitter': {uri: 'https://twitter.com', logoImage: <TwitterLogo/>},
        'instagram': {uri: 'https://instagram.com/cvc_group.by', logoImage: <InstagramLogo/>},
    };
    const targetNetwork = existingSocialNetworks[providedNetworkName];
    return <a className={styles.socialLinkItem} href={targetNetwork.uri} target="_blank" rel="noopener noreferrer">
        {targetNetwork.logoImage}
    </a>;
}

export default SocialLinkItem;