import React from "react";
import { Route, Switch} from "react-router-dom";
import Home from "../Home";
import Solution from "../Solution";
import NotFound from "../NotFound";
import ContactForm from "../../organisms/ContactForm";
import Dialog from "../../molecules/Dialog";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CarWashPromo from '../CarWashPromo/CarWashPromo';

class Root extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            contactFormIsShown: false
        };
        AOS.init();
    }

    toggleContactFormVisibilityState(){
        this.setState({
            contactFormIsShown: !this.state.contactFormIsShown
        });
    }

    render(){
        return (
            <div>
                <div id="top"/>
                <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} handleContactFormToggle={() => {this.toggleContactFormVisibilityState()}}/>} />
                    <Route path="/solutions/:solutionAlias?" render={(props) => <Solution {...props} handleContactFormToggle={() => {this.toggleContactFormVisibilityState()}}/>} />
                    <Route path="/car-wash-promo" render={(props) => <CarWashPromo {...props} handleContactFormToggle={() => {this.toggleContactFormVisibilityState()}}/>} />
                    <Route render={(props) => <NotFound {...props} handleContactFormToggle={() => {this.toggleContactFormVisibilityState()}}/>} />
                </Switch>
                <Dialog isShown={this.state.contactFormIsShown} onDialogClose={() => {this.toggleContactFormVisibilityState()}}>
                    <ContactForm/>
                </Dialog>
            </div>
        );
    }
}

export default Root;