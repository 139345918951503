import React from 'react';
import PromoPageTemplate from '../../templates/PromoPageTemplate/PromoPageTemplate';

const CarWashPromo = (props) => {
    const featureCards = [
        {
            title: 'Фиксация нарушений и воровства администраторов',
            features: ['- сделали «комплекс», пробили мойку', '- мойка без чека'],
            icon: '/promo-assets/car-wash/icons/stealing-icon.png',
        },
        {
            title: 'Подсчёт потока автомобилей',
            features: ['- уведомления клиентам с предложением записаться, когда мойка слабо загружена', '- достоверный учёт'],
            icon: '/promo-assets/car-wash/icons/car-count-icon.png',
        },
        {
            title: 'Контроль мойщиков',
            features: ['- забыли поменять тряпку и поцарапали автомобиль', '- не закрыли люк, залило салон', '- уехали на чужом автомобиле за территорию'],
            icon: '/promo-assets/car-wash/icons/worker-control-icon.png',
        }
    ];

    const shuffleArray = (array)=>{
        return array.map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
    };

    let bgVideos = {
        horizontal: [
            '/promo-assets/car-wash/videos/horizontal-1.mp4',
            '/promo-assets/car-wash/videos/horizontal-2.mp4',
            '/promo-assets/car-wash/videos/horizontal-3.mp4',
            '/promo-assets/car-wash/videos/horizontal-4.mp4',
            '/promo-assets/car-wash/videos/horizontal-5.mp4',
            '/promo-assets/car-wash/videos/horizontal-6.mp4',
            '/promo-assets/car-wash/videos/horizontal-7.mp4',
            '/promo-assets/car-wash/videos/horizontal-8.mp4',
            '/promo-assets/car-wash/videos/horizontal-9.mp4',
            '/promo-assets/car-wash/videos/horizontal-10.mp4',
        ],
        vertical: [
            '/promo-assets/car-wash/videos/vertical-1.mp4',
            '/promo-assets/car-wash/videos/vertical-2.mp4',
            '/promo-assets/car-wash/videos/vertical-3.mp4',
            '/promo-assets/car-wash/videos/vertical-4.mp4',
            '/promo-assets/car-wash/videos/vertical-5.mp4',
            '/promo-assets/car-wash/videos/vertical-6.mp4',
            '/promo-assets/car-wash/videos/vertical-7.mp4',
            '/promo-assets/car-wash/videos/vertical-8.mp4',
            '/promo-assets/car-wash/videos/vertical-9.mp4',
            '/promo-assets/car-wash/videos/vertical-10.mp4',
        ],
    };

    bgVideos.horizontal = shuffleArray(bgVideos.horizontal);
    bgVideos.vertical = shuffleArray(bgVideos.vertical);

    return <PromoPageTemplate {...props} featureCards={featureCards} bgVideos={bgVideos} bgImage={'/car-wash-promo.jpg'}/>
};

export default CarWashPromo;