import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import IntroductionBlock from "../../organisms/IntroductionBlock";
import SystemCapabilitiesBlock from "../../organisms/SystemCapabilitiesBlock";
import SystemSchemeBlock from "../../organisms/SystemSchemeBlock";
import CasesBlock from "../../organisms/CasesBlock";
import VisibleConfusionBlock from "../../organisms/VisibleConfusionBlock";
import ContactsBlock from "../../organisms/ContactsBlock";
import Footer from "../../organisms/Footer";
import DataProtectionBlock from "../../organisms/DataProtectionBlock";

class HomePageTemplate extends React.Component{
    render(){
        return (
            <div>
                <NavigationBar/>
                <IntroductionBlock/>
                <SystemCapabilitiesBlock/>
                <SystemSchemeBlock/>
                <DataProtectionBlock/>
                <CasesBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <VisibleConfusionBlock/>
                <ContactsBlock handleContactFormToggle={this.props.handleContactFormToggle}/>
                <Footer/>
            </div>
        );
    }
}

export default HomePageTemplate;