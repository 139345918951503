import React from "react";
import styles from "./DataProtectionBlock.module.css";
import DataProtectionBanner from "../../molecules/DataProtectionBanner";

class DataProtectionBlock extends React.Component{
    render(){
        return (
            <div className={styles.dataProtectionBlock}>
                <DataProtectionBanner/>
            </div>
        );
    }
}

export default DataProtectionBlock;