import React from "react";
import styles from "./SocialLinks.module.css";
import SocialLinkItem from "../../atoms/SocialLinkItem";

function SocialLinks(props){
    return (
        <div className={styles.socialLinks}>
            <SocialLinkItem name="facebook"/>
            {/*<SocialLinkItem name="twitter"/>*/}
            <SocialLinkItem name="instagram"/>
        </div>
    );
}

export default SocialLinks;