import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Root from "./components/pages/Root";
import TagManager from 'react-gtm-module'

TagManager.initialize({
    gtmId: 'GTM-WLPK7L7'
});

ReactDOM.render(
    <BrowserRouter>
        <Root/>
    </BrowserRouter>,
    document.getElementById('application-root'));
