import React from "react";
import styles from "./NavigationBarDropdown.module.css";
import DropdownListItem from "../../atoms/DropdownListItem";
import DropdownList from "../DropdownList";
import Solutions from "../../../providers/Solutions"

function NavigationBarDropdown(props){
    const solutions = new Solutions();
    return <DropdownList label={<span className={styles.dropdownLabel}>Решения</span>}>
        {solutions.get().map((solution, key) => {
            return <DropdownListItem key={key} to={`/solutions/${solution.alias}#top`} onClick={props.onNavigationItemClick}>{solution.shortTitle}</DropdownListItem>
        })}
    </DropdownList>;
}
export default NavigationBarDropdown;