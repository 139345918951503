import React from "react";
import styles from "./SystemSchemeCardsList.module.css";
import SystemSchemeCard from "../SystemSchemeCard";
import CameraAndServer from "../../atoms/images/SystemSchemeIcons/CameraAndServer";
import CloudAndServer from "../../atoms/images/SystemSchemeIcons/CloudAndServer";
import AnalyticsScreen from "../../atoms/images/SystemSchemeIcons/AnalyticsScreen";

function SystemSchemeCardsList(props){
    return (
        <div className={styles.cardsContainer}>
            <SystemSchemeCard cardIndex='1'>
                <CameraAndServer/>
                <h3>Камеры видеонаблюдения, регистратор либо сервер видеонаблюдения</h3>
            </SystemSchemeCard>
            <SystemSchemeCard cardIndex='2'>
                <CloudAndServer/>
                <h3>Сервер либо облако
                    видеоаналитики
                    на территории заказчика</h3>
            </SystemSchemeCard>
            <SystemSchemeCard cardIndex='3'>
                <AnalyticsScreen/>
                <h3>Полный отчет работы
                    системы, аналитика,
                    инфографика</h3>
            </SystemSchemeCard>
        </div>
    );
}

export default SystemSchemeCardsList;