import React from "react";
import styles from "./SolutionCapabilitiesCard.module.css";
import PlayIcon from "../../atoms/images/PlayIcon";

function SolutionCapabilitiesCard(props){
    return (
        <div data-aos="zoom-in" data-aos-delay={(props.cardIndex - 1) * 100} className={styles.solutionCapabilitiesCard}>
            <PlayIcon/>
            {props.children}
        </div>
    );
}

export default SolutionCapabilitiesCard;