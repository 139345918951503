import React, {useEffect, useState, useCallback} from 'react';
import Button from '../../atoms/Button';
import * as styles from './PromoPageTemplate.module.css';
import {Link} from 'react-router-dom';
import Logo from "../../atoms/images/Logo";
import SliderIndicators from '../../molecules/SliderIndicators';
import useResize from '../../../hooks/useResize';

const PromoPageTemplate = ({featureCards, handleContactFormToggle, bgVideos, bgImage}) => {
    const cardsContainerRef = React.createRef();
    const videoRefs = {
        horizontal: bgVideos['horizontal'].map(() => React.createRef()),
        vertical: bgVideos['vertical'].map(() => React.createRef())
    };
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentScreenMode, setCurrentScreenMode] = useState(window.innerWidth > 768 ? 'horizontal' : 'vertical');
    const [scrollLeft, setScrollLeft] = useState(0);
    let scrollPerClick = 480;
    const [maxScrollLeft, setMaxScrollLeft] = useState(0);
    const [scrollEventHandler, setScrollEventHandler] = useState({f: null});
    const scrollCards = (scrollPerClick) => {
        let newScrollLeft = scrollLeft + scrollPerClick;
        if(newScrollLeft > maxScrollLeft){
            newScrollLeft = maxScrollLeft;
        } else if (newScrollLeft < 0){
            newScrollLeft = 0;
        }
        setScrollLeft(newScrollLeft);
    };

    useEffect(()=>{
        cardsContainerRef.current.scroll({
            left: scrollLeft,
            top: 0,
            behavior: 'smooth'
        });
    }, [scrollLeft]);

    useEffect(()=>{
        setMaxScrollLeft(getMaxScrollLeft());
    }, [cardsContainerRef]);

    useResize(()=>{
        setMaxScrollLeft(getMaxScrollLeft());
        setScrollLeft(cardsContainerRef.current?.scrollLeft || 0);
        if (window.innerWidth >= window.innerHeight && currentScreenMode !== 'horizontal'){
            setCurrentScreenMode('horizontal');
        }else if (window.innerWidth < window.innerHeight && currentScreenMode !== 'vertical'){
            setCurrentScreenMode('vertical');
        }
    });

    const getMaxScrollLeft = ()=>{
        return cardsContainerRef.current?.scrollWidth - cardsContainerRef.current?.clientWidth;
    };

    const assignScrollEventHandler = useCallback(
        (eventHandler) => {
            setScrollEventHandler({f: eventHandler});
        },
        [setScrollEventHandler]
    );

    const handleVideoEnded = (videoIndex)=>{
        const nextVideoIndex = (videoIndex + 1) % bgVideos[currentScreenMode].length;
        setCurrentVideoIndex(nextVideoIndex);
    };

    useEffect(()=>{
        videoRefs[currentScreenMode][currentVideoIndex].current.play();
    }, [currentVideoIndex]);

    useEffect(()=>{
        videoRefs[currentScreenMode][currentVideoIndex].current.play();
    }, [currentScreenMode]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.bannerImageWrapper} style={bgImage && !bgVideos[currentScreenMode][currentVideoIndex].length ? {backgroundImage: `url(${process.env.PUBLIC_URL}${bgImage})`} : {}}>
                <div className={styles.bgGradient}/>
                {bgVideos[currentScreenMode].map((video, index) => {
                    return <video className={`${styles.bgVideo} ${index !== currentVideoIndex ? styles.hidden : ''}`}
                                  ref={videoRefs[currentScreenMode][index]} key={`${index}-${currentScreenMode}`}
                                  playsInline muted onEnded={() => handleVideoEnded(index)}>
                        <source src={`${process.env.PUBLIC_URL} ${bgVideos[currentScreenMode][index]}`} type="video/mp4"/>
                    </video>
                })}

                <div className={styles.bannerContent}>
                    <h1 className={styles.mainHeader} data-aos="fade-down">Заставь свой бизнес работать на все 100%</h1>
                    <div className={styles.cardsWrapper}>
                        <button className={`${styles.scrollCardsButton} ${styles.left} ${scrollLeft === 0 ? styles.hidden : ''}`} onClick={() => scrollCards(-scrollPerClick)}>
                            <span className={styles.chevron}>‹</span>
                        </button>
                        <button className={`${styles.scrollCardsButton} ${styles.right} ${scrollLeft === maxScrollLeft ? styles.hidden : ''}`} onClick={() => scrollCards(scrollPerClick)}>
                            <span className={styles.chevron}>›</span>
                        </button>
                        <div className={styles.cardsContainer} ref={cardsContainerRef} onScroll={scrollEventHandler.f ? (event) => scrollEventHandler.f(event) : null}>
                            {featureCards.map((card, index) => {
                                return <div className={styles.card} key={index} data-aos="slide-left" data-aos-delay={index * 50} >
                                    <div className={styles.cardIndex}>{index+1} / {featureCards.length}</div>
                                    <div className={styles.cardIconWrapper} data-aos="zoom-in" data-aos-delay={index * 100}>
                                        <img src={`${process.env.PUBLIC_URL}${card.icon}`}/>
                                    </div>
                                    <div>
                                        <div className={styles.featureTitle} data-aos="fade-down">{card.title}</div>
                                        {card.features.map((feature, index)=> <div className={styles.feature} key={index} data-aos="fade-up" data-aos-delay={index * 100}>{feature}</div>)}
                                    </div>
                                </div>
                            })}
                            <div className={styles.mock}/>
                        </div>
                        <SliderIndicators assignScrollEventHandler={assignScrollEventHandler} totalAmount={featureCards.length} currentIndex={0}/>
                    </div>
                   <div className={styles.buttonContainer}>
                       <Button role="primary" onClick={handleContactFormToggle}>Связаться с нами</Button>
                       <Link to='/'>
                           <Button role="secondary" style={{color: 'white'}}>Подробнее</Button>
                       </Link>
                   </div>
                </div>

                <div className={styles.aboutContainer}>
                    <div className={styles.logoContainer}>
                        <Logo/>
                    </div>
                    <div className={styles.copyrightText}>Cyber Video Control Group, Минск, Беларусь</div>
                </div>
            </div>
        </div>
    );
};

export default PromoPageTemplate;